@import (inline) '~bootstrap/dist/css/bootstrap.css';
@import (inline) '~awesomplete/awesomplete.css';
@import (inline) '~daemonite-material/css/material.css';

.container-fluid {
  margin-top: 1em;
}

.modal-label {
  font-weight: bold;
}

.footer {
  margin-bottom: 1em;
}

/* why the fuck are the checkboxes misaligned?!?! */
.form-check-label {
  margin-top: 0.25em;
}

.entry-modal .awesomplete mark, .mark {
  padding: 0px !important;
  background-color: #fcf8e3;
}

.entry-modal .awesomplete {
  width: 100%;
}

.entry-modal textarea {
  width: 100%;
}

.service-container label {
  font-weight: bold;
}

.service-container .service-row {
  border-bottom: 1px solid black;
  border-top: 1px solid black;
  padding-bottom: 1em;
  padding-top: 1em;
  padding-left: 1em;
}

.service-container form {
  width: 100%;
}

.service-container .service-row:nth-of-type(even){
    background-color: #dcdcdc;
}

.service-container .service-row:nth-of-type(odd){
    background-color: #aaaaaa;
}

#password_strength_result {
  margin-top: 1em;
  display: block;
}